import { version } from "./version";

export const environment = {
  production: false,
  version: version,
  configMode: 'LOCAL_SERVE',
  // base_url: 'https://api2.doerdo.com/api',
  // base_url: 'https://v1.clinilead.com/api',
  base_url: 'https://a1.clinilead.com/api',
  sentry_dsn: 'https://4d1c0df5b34c47c9b538080ae193a181@o383278.ingest.sentry.io/5275307',
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  student_site_link: 'http://localhost:4200/#/',
  cdn_urls: {
    image: 'https://content.doerdo.com/',
    video: 'https://video.doerdo.com/',
    audio: 'https://video.doerdo.com/audio-media/',
    white_board_content: 'https://wb.doerdo.com/',
    recording: 'https://recording.doerdo.com/',
    class_asset: 'https://wb.doerdo.com/class_assets/',
    import_files: 'https://dd2-files.s3.ap-southeast-1.amazonaws.com/qf_imported/',
  },
};
